export const LATEST_FEED_ITEM_WRAPPER_TOPIC_FRAGMENT = `
  fragment latestFeedItemWrapperTopicFragment on Topic {
      uid
      feed: _topicFeed(
        skip: 0
        limit: 1
        myUid: "%authUser%"
      ) {
          uid
      }
  }
`;
